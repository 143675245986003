import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
// components
import GridElement from "../../components/gridElement"
import TopElement from "../../components/topElement"
import Layout from "../../components/layout"
import Seo from "../../components/seo"


const CraftServicesPage = ({ data }) => {
  const gipsReferenceImage = getImage(data.gipsReferenceImage.childImageSharp.gatsbyImageData)
  const stuckReferenceImage = getImage(data.stuckReferenceImage.childImageSharp.gatsbyImageData)
  const trockenbauReferenceImage = getImage(data.trockenbauReferenceImage.childImageSharp.gatsbyImageData)
  const akustikdeckenReferenceImage = getImage(data.akustikdeckenReferenceImage.childImageSharp.gatsbyImageData)
  const stuccoReferenceImage = getImage(data.stuccoReferenceImage.childImageSharp.gatsbyImageData)
  return (
    <Layout initialBackgroundColor="bg-white"
      headerTitle='Technik und Tradition.'
      headerSub='Gips. Stuck. Trockenbau.'
      bgImg='handwerkLeistung'>
      <Seo title="Handwerk aus Leidenschaft" />

      <TopElement text='Gips. Stuck. Trockenbau. Das sind unsere Spezialitäten. Dahinter stehen handwerkliche Techniken, welche die Baukunst seit Jahrtausenden prägen. Wir beherrschen diese traditionsreichen Techniken – und wenden Sie selbstverständlich auch in modernen Bauten an. Effizient und verlässlich.'/>

      <div className="grid max-w-screen-lg gap-16 mx-auto mb-24 hm-grid md:gap-48 md:mt-12">
        <GridElement
          title="Gips"
          text="Der Brandschutz: hoch. Die Feuchtigkeitsregulierung: optimal. Der natürliche und sehr vielseitig einsetzbare Baustoff Gips bietet viele Vorteile. Was Gips hingegen nicht aufweist, das sind Schadstoffe. Gips löst somit keine Allergien aus, sondern sorgt vielmehr für ein gesundes Raumklima. Die Firma Hossmann führt vom Grundriss zum Deckputz sämtliche Gipserarbeiten aus. Exakt und hochwertig.">
          <GatsbyImage
            image={gipsReferenceImage}
            className="bild"
            alt=""
          />
        </GridElement>
        <GridElement
          title="Stuck"
          text="Die Technik: bewährt. Die Ästhetik: hervorragend. Stuck prägte die Baukunst insbesondere in der Barockzeit. Stuck eignet sich als vielseitig gestaltbares Element aber auch für moderne Räume. Stuckstäbe beispielsweise lassen sich mittels Schablonen oder Silikonformen anfertigen und einfach anbringen. Die Firma Hossmann führt sämtliche Stuck-Arbeiten aus. Traditionell und innovativ.">
          <GatsbyImage
            image={stuckReferenceImage}
            className="bild"
            alt=""
          />
        </GridElement>
        <GridElement
          title="Trockenbau"
          text="Das Prinzip: einfach. Das Resultat: überzeugend. Unter Trockenbau versteht man das Erstellen von Wänden und Decken ohne statische Aufgaben und ohne wasserhaltige Baustoffe. Die Vorteile: Der Trockenbau verkürzt die Bauzeit, verhindert Feuchtigkeitsrückstände und lässt sich vielseitig anwenden. Die Firma Hossmann führt sämtliche Trockenbau-Arbeiten aus. Flexibel und genau.">
          <GatsbyImage
            image={trockenbauReferenceImage}
            className="bild"
            alt=""
          />
        </GridElement>
        <GridElement
          title="Akustikdecken"
          text="Der Schall- und Geräuschpegel: gering. Die Raum- und Klangqualität: hervorragend. Mit Akustikdecken lassen sich Räume so auskleiden, dass störende Geräusche optimal gedämmt werden. Akustikdecken eignen sich für Säle ebenso wie für Wohnzimmer. Angebracht werden sie ganz einfach mit einer Unterkonstruktion an der Decke. Die Firma Hossmann stimmt Akustikdecken exakt auf Ihre Bedürfnisse ab. Wirkungsvoll und ästhetisch überzeugend.">
          <GatsbyImage
            image={akustikdeckenReferenceImage}
            className="bild"
            alt=""
          />
        </GridElement>
        <GridElement
          title="Stucco"
          text="Die Wände: elegant. Die Decken: wirkungsvoll. Stucco verleiht Wänden und Decken eine besondere Struktur und einen besonderen Glanz. Die Struktur ergibt sich durch eine besondere Spachteltechnik, der Glanz durch das Einwachsen und Polieren der Oberfläche. Die Firma Hossmann führt Stucco-Arbeiten mit viel Erfahrung und ganz nach Ihren Wünschen aus. Kunst- und stilvoll.">
          <GatsbyImage
            image={stuccoReferenceImage}
            className="bild"
            alt=""
          />
        </GridElement>
      </div>
    </Layout>
  );
}

export default CraftServicesPage

export const pageQuery = graphql`{
  gipsReferenceImage: file(
    relativePath: {eq: "references/esplanade-belle-epoque-davos-living-room-1.jpg"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 80, layout: FULL_WIDTH)
    }
  }
  stuckReferenceImage: file(
    relativePath: {eq: "references/grand-resort-bad-ragaz-dining.jpg"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 80, layout: FULL_WIDTH)
    }
  }
  trockenbauReferenceImage: file(
    relativePath: {eq: "references/villa-morcote-deckenbekleidung.jpg"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 80, layout: FULL_WIDTH)
    }
  }
  akustikdeckenReferenceImage: file(
    relativePath: {eq: "references/gkb-auditorium-akustikdecke.jpg"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 80, layout: FULL_WIDTH)
    }
  }
  stuccoReferenceImage: file(
    relativePath: {eq: "references/valsana-hotel-arosa-entrance.jpg"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 80, layout: FULL_WIDTH)
    }
  }
}
`
