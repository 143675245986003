import React from 'react'
import PropTypes from 'prop-types'
import '../assets/css/grid-element.css'

const GridElement = ({ title, text, children }) => {

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-4 md:gap-8 lg:gap-12 mx-auto md:px-4 lg:px-0">
      <div className="col-span-1 lg:col-span-2 justify-end">
        <h1 className="h2 text-left text-5xl text-black mb-5 pt-10">{title}</h1>
        <p className="text-left mb-5 md:pl-0 pb-4 md:pb-0 md:text-lg">{text}</p>
      </div>
      <div className="col-span-1 lg:col-span-4">
        <div className="absolute border-b-3 border-secondary w-16 h-18 z-20 mt-20 hidden md:block" />
        {children}
      </div>
    </div>
  )
}

GridElement.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  text: PropTypes.string,
}


export default GridElement